<template>
        <el-dialog title="Abonati TheBizz" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
            <el-col :md='8'>
                <el-form-item label='Nume' >
                    <el-input  class='full-width' v-model='selectedObject.Nume' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Firma Afiliere Institutionala' >
                    <el-input  class='full-width' v-model='selectedObject.FirmaAfiliereInstitutionala' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Email' >
                    <el-input  class='full-width' v-model='selectedObject.Email' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Tip Abonament' >
                     <el-select class='full-width' v-model='selectedObject.TipAbonament' ><el-option label='TheBizzMagazine' value='TheBizzMagazine'></el-option><el-option label='TheBizzMagazineSiDeBizzMagazine' value='TheBizzMagazineSiDeBizzMagazine'></el-option>
                     </el-select>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Date Facturare' >
                    <el-input  class='full-width' v-model='selectedObject.DateFacturare' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Adresa Livrare' >
                    <el-input  class='full-width' v-model='selectedObject.AdresaLivrare' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Membru Activ' >
                    <el-checkbox v-model='selectedObject.MembruActiv' true-label='1' false-label='0'> Membru Activ </el-checkbox>
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Abonat Newsletter' >
                    <el-checkbox v-model='selectedObject.AbonatNewsletter' true-label='1' false-label='0'> Abonat Newsletter </el-checkbox>
                </el-form-item>
            </el-col>            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Abonati_thebizz_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: {
                    Nume: '' , FirmaAfiliereInstitutionala: '' , Email: '' , TipAbonament: '' , DateFacturare: '' , AdresaLivrare: '' , MembruActiv: '' , AbonatNewsletter: '' ,                 },
                Info:{
                                    },
                rules: {
                    //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
                    Nume: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    FirmaAfiliereInstitutionala: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Email: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    TipAbonament: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DateFacturare: [ { required: false, message: 'Campul este obligatoriu' } ], 
                    AdresaLivrare: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    MembruActiv: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    AbonatNewsletter: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                    this.selectedObject = {};
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("abonati_thebizz/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("abonati_thebizz/get_info_for_dialog" );
                            },
                        save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("abonati_thebizz/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>